import React from 'react';
import Page from '../components/templates/page';
import SiteWrapper from '../siteWrapper';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';

const Sommerlager = (props) => {
    
    const data = props.data.allFile.edges[0].node.childMarkdownRemark.frontmatter;
    const body = props.data.allFile.edges[0].node.childMarkdownRemark.html;

    console.log(body);

    return (
        <SiteWrapper>
            <Helmet>
              <title>KjG St. Barbara / Littenweiler - Impressum</title>
              <meta name="description" content="Die KjG St. Barbara aus Freiburg-Littenweiler bietet Sommerlager (Zeltlager), wöchentliche Gruppenstunden und andere coole Aktionen für Jugendliche und Kinder. Schau rein, was wir zu bieten haben!" />
              <meta name="keywords" content="KjG, Littenweiler, Freiburg, St. Barbara, Sommerlager, Zeltlager, Jugendfreizeit, Kinderfreizeit" />
            </Helmet>
            <Page data={data} body={body} />
        </SiteWrapper>
    )
}

export default Sommerlager;

export const query = graphql`
  query {
    allFile (filter: {sourceInstanceName: {eq: "sites"} name: {eq: "impressum"}}) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              title
              untertitel
              color
              featuredImg {
                childImageSharp {
                  gatsbyImageData(width: 1920)
                }
              }
            }
            html
        }
      }
    }
  }
}`